import React, {useState, useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Colors from '../../utils/colors';
import Header from '../header';
import RightBox from './rightBox';
import { FiMenu, FiMoreVertical } from 'react-icons/fi';
import {FaPowerOff, FaUser} from 'react-icons/fa';
//import MobileMenu from '../sidebar/mobile';
import PrimaryButton from '../button/primary';
import useAlert from '../../utils/toast'
import { removeAuth } from '../../redux/actions/authAction';

const mapStateToProps = state => state.Auth;



function AppHeader({Auth, header}){
let [user, setUser] = useState({});
let [open, setOpen] = useState(false);
let [power, setPower] = useState(false)

let navigate = useNavigate();
let dispatch = useDispatch();
let alert = useAlert();

async function signout(){
   await dispatch(removeAuth());
   alert.toast("You have logged out.", 'warning')
   navigate("/")
}

useEffect(()=>{
   if(Auth==null) return; 
   let u = Auth.user; 
   setUser(u);
}, [])
let br = false; // use this to revert back to previous color
let height = "55px";
return(
<>
<div className={'headerBox'} style={{ backgroundColor: Colors.bg11}}>
   {/*On desktop*/}
   <div style={{width:'100%', height:height, display:'flex', justifyContent:'space-between',borderBottom:"solid 0px "+ Colors.gray,}}>
      <div  style={{display:"flex", alignItems:'center'}}>
         <div className="hide-on-mobile" style={{height:height, width:'230px', borderBottom:`1px solid ${ Colors.bluegray} `, backgroundColor:Colors.bg2, display:'flex', alignItems:'center', paddingLeft:'25px'}}>
            <div >
               <img alt="logo" style={{height:'35px', }} src="/img/blacklogo.png" />
            </div>
         </div>
         <div className="show-on-mobile" >
            <div style={{display:"flex", minWidth:'50px', height:height, alignItems:'center', borderRight:`1px solid ${br ? "#9ac4e0" : Colors.gray} `, justifyContent:'center'}}>
               <div  onClick={open ? null : ()=>{ setOpen(!open); }} style={{justifyContent:'center', alignItems:'center', color:br ? "#9ac4e0": Colors.gray, fontWeight:600, fontSize:'1.2em', cursor:'pointer'}}>
                     <FiMenu />
               </div>
            </div>
         </div>
         <div style={{height:height, display:'flex', alignItems:'center', marginLeft:"1.5em", color:Colors.black, fontSize:"18px", fontWeight:600, }}>
            {header || "header"}
         </div>
      </div>

      <div style={{height:'100%', display:'flex', alignItems:'center', marginRight:'10px',  }}>
         <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center", }}>
            <Link to={"/settings/personal-profile"}>
               <div className='power-box'>
                  <FaUser color={Colors.primary}  />
               </div>
            </Link>
            <div className='power-box ' onClick={()=>setPower(true)}>
               <FaPowerOff color={Colors.danger} />
            </div>
         </div>
      </div>
      
      <div style={{height:'100%', borderLeft:`1px solid ${br ? "#9ac4e0" : Colors.gray} `, display:'none', alignItems:'center', marginRight:'10px',   }}>
         <RightBox user = {user} />
      </div>
   </div>
</div>
   {/* <MobileMenu open={open} close={()=>setOpen(false)} /> */}

      <Dialog visible={power} closeOnEscape  onHide={()=>setPower(false)} 
         style={{ width: '25vw' }} breakpoints={{ '960px': '50vw', '641px': '95vw' }}>
         <div style={{paddingTop:"1px"}} >
            <div className='h6' style={{color:Colors.black}}>Do you want to signout of <span style={{color:Colors.black}}>MonyTrack</span><span style={{color:Colors.primary}}> Admin Portal</span>?</div>

            

            <div style={{marginTop:"20px"}}>
               <PrimaryButton size={'small'}
                  label={"Yes, Sign me out!"} callback={signout} bg={Colors.danger} width="100%"
                />
            </div>
            <div style={{marginTop:"12px"}}>
               <PrimaryButton label={"No, Cancel"} callback={()=>setPower(false)} size={'small'}
                  width="100%"  color={Colors.black} bg={Colors.bg3}
                />
            </div>
         </div>
      </Dialog>
</>
)
}
export default connect(mapStateToProps, null)(AppHeader);