
/*Check screen size for header sizes*/
const ThemeSizes= {
   text:{
      p1:"22px",
      p2:"20px",
      p3:"18px",
      p4:"16px",
      p5:'14px',
      p6:'12px',
   },
   header:{
      h1:"40px",
      h2:"30px",
      h3:"25px",
      h4:"20px",
      h5:'15px',
      h6:'12px',
   }

}
export default ThemeSizes;