import React, {useState, useEffect} from 'react';
import AppHeader from '../components/head';
import AppSidebar from '../components/sidebar/index';
import AccessManager from './access';
//import Page from '../components/layout/page';
import Colors from './colors'



function PageBuilder({PageComponent, access, pageProps, title, header}){
let [w, setW] = useState("230px");
let [typ, setTyp] = useState("")
useEffect(()=>{
   let wi = localStorage.getItem("Urim"); 
   if(!wi || wi==undefined || wi==null || wi =="l"){
      setW("230px")
   }
   else {   setW("60px") }
}, []);

function adjust(type){ 
   if(type=="minimize"){ setW("60px"); setTyp("minimize")   }
   if(type=="maximize"){ setW("230px");   setTyp("maximize")  }
}
/*I'll need to come back to this later
Next main thing is to show menu on hover
*/
return(
<>
<>
      <title>{title ? title +" - MonyTrack" : "MonyTrack Admin" }</title>
      <meta name="description" content="Login to your merchant account" />
      <meta name="theme-color" content ={ Colors.primary } />
      <meta name="msapplication-navbutton-color" content ={ Colors.primary } />
      <meta name="apple-mobile-web-app-status-bar-style" content ={ Colors.primary } />
</>
<div>
   <div>
      <AppHeader header={header}/> 
   </div>
   <div style={{width:'100%', marginTop:'55px',}}>
      <div className="hide-on-mobile" style={{display:'inline-block', width:w, position:"fixed", left:'0px'}}>
         <AppSidebar callback={adjust} type={typ}  />
      </div>
      <div className="full-width-on-mobile no-margin-on-mobile" style={{display:'inline-block', marginLeft:w, width:"calc( 100% - "+ w +" )", padding:"1.5em"}}>
         <AccessManager access={access}>
            <PageComponent {...pageProps} />
         </AccessManager>
      </div>
   </div>
</div>
</>
)
}
export default PageBuilder;