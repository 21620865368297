import React, {useRef} from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FaQuestion } from "react-icons/fa";
import { Button } from 'primereact/button';
import Colors from "../../utils/colors";



function ConfirmDialogModal({open, close, callback, title, message, aLabel, rLabel}){

return (
<>
    <ConfirmDialog
        group="headless" 
        visible = {open} onHide={close}
        style={{ width: '27vw' }} breakpoints={{ '960px': '50vw', '641px': '95vw' }}
        content={({ headerRef, contentRef, footerRef, hide, }) => (
            <div style={{backgroundColor:Colors.light, display:'flex', borderRadius:"10px"}}
                className="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div style={{height:"5rem", width:"5rem", borderRadius:"50%", marginTop:"-5rem", backgroundColor:Colors.primary, display:'flex', justifyContent:'center', alignItems:'center'}}
                className="inline-flex justify-content-center align-items-center">
                    <FaQuestion size={30} color={Colors.light} />
                </div>
                <span className="h5 medium mb-2 mt-3" style={{color:Colors.black}} >
                            {title||"Title"}
                </span>
                <p className="mb-0 mt-1 h6" style={{color:Colors.gray, textAlign:"center" }}>
                            {message || "Message"}
                </p>
                <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
                    <Button
                        label={aLabel || "Yes, approve" }
                        style={{backgroundColor:Colors.primary, marginRight:"10px", borderRadius:"10px", border:"none"}}
                        onClick={(event) => {
                                    hide(event);
                                    callback("accept");
                                }}
                        className="w-8rem"
                    ></Button>
                    <Button
                        label={rLabel || "No, cancel"}
                        style={{borderColor:Colors.primary, borderRadius:"10px", color:Colors.primary,}}
                                outlined
                                onClick={(event) => {
                                    hide(event);
                                    callback('reject');
                                }}
                                className="w-8rem"
                    ></Button>
                </div>
            </div>
        )}
    />
    <style>{`
    .p-button-label{font-weight:500;}
    .p-5 { padding: 2rem !important;}
    .align-items-center {  align-items: center !important;}
    .flex-column { flex-direction: column !important;}
    .table-head {background-color: ${Colors.bg2}; color:${Colors.black}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    
    `}</style>
</>
)
}
export default ConfirmDialogModal