import {
FiHome,
FiCreditCard,
FiUserCheck,
FiSend,
FiShuffle
}
from 'react-icons/fi';
import {
   MdAccountBalance,
   MdDashboard,
   MdAccountBalanceWallet,
   MdAddShoppingCart,
   MdSettings,
   MdPeople,
   MdSwapHoriz, 
} from 'react-icons/md';
import { FaChartPie, FaRegCreditCard,  } from "react-icons/fa";



const Icons = {
   Dashboard: MdDashboard,
   Accounts: MdAccountBalance,
   Credit: FiCreditCard,
   Payments: MdAccountBalanceWallet,
   Sales: MdAddShoppingCart,
   Invoice: FiSend,
   Invoicing: FiSend,
   Transfers: MdSwapHoriz,
   HR: FiUserCheck,
   Team: MdPeople,
   Settings:MdSettings,
   Reports:FaChartPie,
   Cards:FaRegCreditCard,
}

export default Icons;