import React, {useState} from "react";
import { Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Colors from "../../../utils/colors"
import DirectorModal from "./d-modal";





function DirectorsList({people, cid}){
let [open, setOpen] = useState(false); 
let [item, setItem] = useState(null)

function ViewComp(c){
    return <div className="table-view-link">
        <Link to={"#"} onClick={()=>{ setOpen(true); setItem(c); } } >View</Link>
    </div>
}

return(
<>
    <div>
        <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:'10px'}}>
            <DataTable resizableColumns 
                    value={people || []} size="small" stripedRows ={false} rowHover
                    tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                    <Column field="sn" header="#" className="h65 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                    <Column field="firstname" header="Firstname" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="lastname" header="Larstname" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="email" header="Email" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="country" header="Country" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="email" header="Type" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="verification_status" header="V Status" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="status" header="Date" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    <Column field="qty" header="Action" body={ViewComp} className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
            </DataTable>
        </div>
    </div>
    <DirectorModal open={open} close={()=>setOpen(false)} d={item} cid={cid} />
    <style>{`
    .table-head {background-color: ${Colors.bg2}; color:${Colors.black}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    `}</style>
</>
)
}
export default DirectorsList