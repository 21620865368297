import { useToasts,  AppearanceTypes } from 'react-toast-notifications';
import { toast } from 'react-toastify';

function useAlert(){
   let { addToast } = useToasts();
   return {
      toast: (message, type)=>{
         toast(message, {
            position: "top-center",
            type: type || "default"
          })
         //addToast(message, {appearance:type}); 
         },
   }
}

export default useAlert;
