import {Link} from 'react-router-dom' ;
import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import CardNormal from '../../components/cards/cardNormal';
import Input from '../../components/input/input';
import Colors from '../../utils/colors';
import AuthSidebar from './components/sidebar';
import useApi from '../../utils/api';
import useValidation from '../../utils/validate';
import useAlert from '../../utils/toast'; 


function PasswordRecoveryOTPView(){
let [busy, setBusy] = useState(false);
let [otp, setOtp] = useState({});

let api = useApi();
let validate = useValidation();
let alert = useAlert();
let navigate = useNavigate()
let location = useLocation();


async function verify(){
   let err = false;
   if(!otp.v || otp.v.length < 6  ){
      otp.e=true; otp.m="Provide a valid OTP "; setOtp(JSON.parse(JSON.stringify(otp))); err=true;
   }
   else{ otp.e=false; err=false; setOtp(JSON.parse(JSON.stringify(otp))); }
    
   if(err){  return;  }
   let p = {email:location?.state?.email, otp:otp.v }
   setBusy(true)
   let result = await api.post('auth/reset/validate-otp', p); //console.log(result);
   setBusy(false)
   if(result.status=="ok"){
      alert.toast(result.message, 'success')
      navigate("/auth/reset-password", {state:{email:location?.state?.email, otp:otp.v}})
   }
   else { alert.toast(result.message, 'error') }
}

return(
   <div className=' no-margin' style={{minHeight:"100vh"}}>

      <div style={{width:"100%", minHeight:"95vh", display:'flex', justifyContent:'center', alignItems:"center"}}>
         <div className='col-md-5 no-padding'>
            <CardNormal styles={{border:"none", boxShadow:"none", backgroundColor:"none"}}>
               <div align="center" style={{margin:"15px 0px", padding:"0px 10px"}}>
                  <div className='bold h2' style={{color:Colors.primary}}><b>Recovery OTP</b> </div>
                  <div className='regular h5' style={{color:Colors.gray, marginTop:"5px"}}>We sent an OTP to your email at mail@example.com. Enter the OTP below to continue</div>

                  <div style={{marginTop:"35px"}} align="left">
                     <Input
                        placeholder={"Enter OTP"} label="OTP" bg={Colors.bgShade1} type="number" limit={8}
                        value={otp.v} callback={(v)=>{otp.v=v; setOtp(JSON.parse(JSON.stringify(otp)))}}
                        hasError={otp.e} errorMessage={otp.m}
                      />
                  </div>
                   
                  <div style={{margin:"15px 0px", marginTop:"30px"}} align="left">
                     <PrimaryButton
                        label={"  Submit  "} busy={busy} callback={verify}
                      />
                  </div>
                   
               </div>
            </CardNormal>
         </div>
      </div>


      <div style={{minHeight:"5vh", backgroundColor:Colors.primary}}>
         
      </div>
   </div>
)
}
export default PasswordRecoveryOTPView;