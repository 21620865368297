import React, {useState} from 'react';
import MenuItem from "./menuItem";
import Colors from '../../utils/colors';
const menus = require("../../utils/data/menu.json");

function SidebarMenu(){
return(
<div className="overlay-y sidebar-bg-" style={{height:"calc( 100vh - 127px)", paddingBottom:'20px', backgroundColor:Colors.bg2}}>
   {menus.map((menu, index)=>
      <MenuItem key={index} menu={menu} />
   )}
</div>
)
}
export default SidebarMenu;