import React, {useState} from 'react';
//import "../../styles/header.module.css";
import Colors from "../../utils/colors";

//import MerchantsBox from "./mbox";
//import MerchantsBoxS from "./smbox";
import SidebarMenu from "./sidemenu";

function AppSidebar({callback, type}){
let [full, setFull] = useState(true);
let [mini, setMini] = useState(false);
let [status, setStatus] = useState("");

const process=(val)=>{
   if(val=="minimize"){
      setFull(false);
      setTimeout(()=>{
         setMini(true); //console.log("done")
      },500)
   }
}

let f = full ? "animate__animated animate__fadeInLeft" : "animate__animated animate__fadeOutLeft";
let m = mini ? "animate__animated animate__fadeInLeft" : "animate__animated animate__fadeOutLeft";
return(
<div className={ 'sidebarContainerFull' + " hide-on-mobile" } style={{
   backgroundColor:Colors.bg2, display:'block',  width:"100%", height: "calc(100vh - 45px)"
}}>
   {type=="minimize" ?
   <div >
      {/*<MerchantsBoxS callback={callback} type={type}  />
      <SidebarMenu  /> */}
   </div>  
   :
   <div style={{paddingTop:"30px"}} >
      {/*<MerchantsBox callback={callback} type={type}  />*/}
      <SidebarMenu  />
   </div> 
   } 
</div>
)
}

export default AppSidebar;