import { Link } from 'react-router-dom';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import CardNormal from '../../components/cards/cardNormal';
import Input from '../../components/input/input';
import Colors from '../../utils/colors';
import useApi from '../../utils/api';
import useValidation from '../../utils/validate';
import useAlert from '../../utils/toast'; 


function PasswordForgotView(){
let [busy, setBusy] = useState(false);
let [email, setEmail] = useState({});

let api = useApi();
let validate = useValidation();
let alert = useAlert();
let navigate = useNavigate()


async function verify(){
   let err;
   if(!email.v || email.v.length < 5 || !validate.isEmail(email.v)){
      email.e=true; email.m="Provide a valid email address"; setEmail(JSON.parse(JSON.stringify(email))); err=true;
   }
   else{ email.e=false; err=false; setEmail(JSON.parse(JSON.stringify(email))); }
    
   if(err){  return;   }
   //
   let p = {email:email.v }
   setBusy(true)
   let result = await api.post('auth/reset/verify-email', p); //console.log(result);
   setBusy(false)
   if(result.status=="ok"){
      alert.toast(result.message, 'success')
      navigate("/auth/password-otp", {state:{email:email.v}})
   }
   else { alert.toast(result.message, 'error') }
}

return(
   <div className=' no-margin' style={{minHeight:"100vh"}}>

      <div style={{width:"100%", minHeight:"95vh", display:'flex', justifyContent:'center', alignItems:"center"}}>
         <div className='col-md-5 no-padding'>
            <CardNormal styles={{border:"none", boxShadow:"none", backgroundColor:"none"}}>
               <div align="center" style={{margin:"15px 0px", padding:"0px 10px"}}>
                  <div className='bold h2' style={{color:Colors.primary}}><b>Recover Password</b> </div>
                  <div className=' h5' style={{color:Colors.gray}}>Can't remember your password? Enter your email below to reset your password</div>

                  <div style={{marginTop:"35px"}} align="left">
                     <Input
                        placeholder={"Enter your email"} label="Email" bg={Colors.bgShade1} type="email"
                        value={email.v} callback={(v)=>{email.v=v; setEmail(JSON.parse(JSON.stringify(email)))}}
                        hasError={email.e} errorMessage={email.m}
                      />
                  </div>
                   
                  <div style={{margin:"15px 0px", marginTop:"30px"}} align="left">
                     <PrimaryButton
                        label={"  Submit  "} busy={busy} callback={verify}
                      />
                  </div>
                  <div align="center" style={{margin:"15px 0px", marginTop:"20px"}}>
                     <div className='bold h6'>Want to login instead?</div>
                     <Link to={"/"}>
                        <div className='h6' style={{color:Colors.primary, cursor:"pointer", marginTop:"5px"}}>Login here</div>
                     </Link>
                  </div>
               </div>
            </CardNormal>
         </div>
      </div>


      <div style={{minHeight:"5vh", backgroundColor:Colors.primary}}>
         
      </div>
   </div>
)
}
export default PasswordForgotView;