import React from "react";

import Colors from "../../../utils/colors";


function TextGridRows({grid}){
if(!grid || typeof(grid) !='object') return null;

let c = 12 / grid.length;
return (
    <div className="row no-margin" style={{alignItems:"stretch"}}>
        {
            grid.map((g, i)=>
                <div className={`col-md-${g.w || c} no-padding`} style={{flex:1, alignSelf:'flex-end'}}>
                    <div 
                        className= {i==grid.length-1 ? "b-bottom": "b-right b-bottom"}
                        style={{padding:i==0 ? "10px 5px 10px 0px" : "10px 5px", height:'100%' }}>
                        <div className="h7 txt-col-label-1" >{g?.label}:</div>
                        <div className="h6 txt-col-text-1" >{g?.text || "NA"}</div>
                    </div>
                </div>
            )
        }
    </div>
)
}
export default TextGridRows;
