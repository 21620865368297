import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
//import "../../styles/header.module.css";
import ThemeColors from "../../utils/colors";
import Icons from './menuIcons';
import Colors from "../../utils/colors";

function MenuItem({menu, callback}){
let [open, setOpen] = useState(false);
let [path, setPath] = useState("");
let MenuIcon = Icons[menu.menu.replaceAll(" ", "")] ? Icons[menu.menu.replaceAll(" ", "")]: Icons["HR"];

let navigate = useNavigate()
useEffect( ()=>{
   let path = window.location.pathname; 
   setPath(path);
} ,[]);
let activetest = path.split("/")[1]?.includes(menu.url.split("/")[1])
//let activetest = path.includes(menu.url); 

useEffect(()=>{
   setOpen(activetest)
}, [activetest])

const process=()=>{
   if(menu.children.length > 0){
      setOpen(!open);
   }
   else{
      navigate(menu.url)
   }
}

return(
<div style={{width:'100%', padding:"0px 15px", fontSize:"15px"}}>
   <div onClick={process} className={activetest? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:"calc(100% - 25px)"}}>
         <div className={'userIconBox white-on-hover'} style={{color:Colors.black}} >
            <MenuIcon color={activetest? "#fff" : "inherit"} />
         </div>
         <div className="white-on-hover" style={{color:activetest? "#fff" : Colors.black}}>{menu.fullname || menu.menu}</div>
      </div>
      <div className="white-on-hover" style={{width:'20px', color:Colors.black}}>
         {menu.children.length > 0 && (
            open ? 
            <FiChevronUp color={activetest? "#cfe4f1" : "inherit"} />
            : <FiChevronRight color={activetest? "#cfe4f1" : "inherit"} />
         )}
      </div>
   </div>
   {open?
   <div className={ open ? "animate__animated animate__slideInUp" : "animate__animated animate__slideOutUp"} style={{paddingLeft:'2.5em'}}>
      {menu.children.map((item, index)=>
      <Link to={item.url} key={index}>
      <div key={index} className={'submenu'} style={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:'7px'}}>
         <div className={'menuSquare'} 
            style={{backgroundColor: path==(item.url) ? "#cfe4f1":"#549ccc"}} ></div>
         <div 
            style={{
               color:item.hasChildren ?
                     path.includes(item.key) ? "#cfe4f1":"inherit"
                     :
                     path==(item.url.includes("?") ? item.url.split("?")[0] : item.url ) ? "#cfe4f1":"inherit", 
               fontSize:'13px'
               }}> 
            {item.item}
         </div>
      </div>
      </Link>
      )}
   </div>
   :null}
</div>
)
}
export default MenuItem;