import React from "react";
import { Tooltip } from 'primereact/tooltip';
import Colors from "../../utils/colors";




function SwitchBox({status, callback, tip}){
return(
<>
    <div className="pr-tip" onClick={callback ? callback : null}       data-pr-tooltip={tip} data-pr-position="left"
        style={{
            width:"50px", height:"20px", borderRadius:"10px", cursor:"pointer", padding:"3px 4px", 
            backgroundColor:status==true ? Colors.green : Colors.gray1,
            display:'flex', alignItems:"center", justifyContent:status==true ? "flex-end" : "flex-start"
            }}>
        <div style={{height:"16px", width:"16px", borderRadius:"50%", backgroundColor:status==true ? Colors.light : Colors.light}}></div>
    </div>
    <Tooltip target=".pr-tip" style={{fontSize:"12px"}} />
</>
)
}
export default SwitchBox;