import { createBrowserRouter} from "react-router-dom";
import AuthRoutes from "./routes/auth";
import AuthenticatedRoutes from "./routes/dashroutes";


const App = createBrowserRouter([
  ...AuthRoutes,
  ...AuthenticatedRoutes,
])




export default App;