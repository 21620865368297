import React, {useState, useEffect} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../utils/colors";
import useAlert from "../../utils/toast";
import useApi from "../../utils/api";
import { dashGetInactives } from "../../redux/actions/dashActions";


const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]

function DashboardView(){
let [list, setList] = useState([])

let api = useApi();
let alert = useAlert();
let dispatch = useDispatch();
let loading = useSelector((s)=>s.Dash?.Loading);
let dash = useSelector((s)=>s.Dash?.Inactives);

useEffect(()=>{
    if(!dash || dash==null ){
        dispatch(dashGetInactives(api, alert))
    } else {
        let last3 = (new Date).getTime() - 60*60*3*1000; 
        if(dash?.timestamp < last3){ 
            dispatch(dashGetInactives(api, alert));
        } else {    setList(dash.list)}
    }
}, [dash?.list])

return(
<>
    <div style={{width:"100%", padding:"1em", backgroundColor:Colors.bg1, borderRadius:"5px"}}>
        <div className="bold">Inactive Businesses</div>


        {
            list && list?.length > 0 ?

            <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:'20px'}}>
                    <DataTable 
                        value={[]} size="small" stripedRows ={false} rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Name" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Email" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="qty" header="Date Joined" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                    </DataTable>
            </div>
            :
            <div className="h6" style={{marginTop:"10px", color:Colors.gray}}>
                There are no inactive Businesses at this time. Businesses become inactive when there is no transaction in the last 45 days.
            </div>
        }
    </div>
    <style>{`
    .table-head {background-color: ${Colors.bg2}; color:${Colors.black}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</>
)
}
export default DashboardView;