
import LoginPage from '../views/auth/login';
import ForgotPasswordPage from '../views/auth/forgot';
import ForgotPasswordOTP from '../views/auth/otp';
import ForgotPasswordReset from '../views/auth/reset';

const AuthRoutes =[
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/auth/forgot-password",
      element: <ForgotPasswordPage />,
   },
  {
    path: "/auth/password-otp",
    element: <ForgotPasswordOTP />,
   },
   {
    path: "/auth/reset-password",
    element: <ForgotPasswordReset />,
   },
    
  ];
  
  export default AuthRoutes;