import React from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import Colors from "../../../utils/colors";


function CellLink({text, link}){
return (
    <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
        <Link to={link} className="links h65" target="_blank">
            {text}
        </Link>
        <FiExternalLink size={"13px"} color={Colors.primary} style={{marginLeft:"3px"}} />
    </div>
)
}
export default CellLink;