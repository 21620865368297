export const AUTH_DO ="ADD_AUTH";
export const AUTH_REMOVE = "REMOVE_AUTH";
export const REG_NEW = "NEW_REG";
export const AUTH_MODAL_SET = "ACTIVATE_AUTH_MODAL";
export const AUTH_MODAL_REMOVE = "REMOVE_AUTH_MODAL";

export const RESET_STORE = "RESET STORE";

/*Dashboard*/

export const DASH_START_LOADING = "DASH START LOADING";
export const DASH_STOP_LOADING ="DASH STOP LOADING";
export const DASH_INACTIVES = "DASH INACTIVES"; 
export const DASH_COMPLY = "DASH RECENT"; 
export const DASH_SWIFT ="DASH ACCOUNT";


 

/*Team*/

export const TEAM_LIST = "TEAM_LIST"; 
export const TEAM_START_LOADING = "TEAM_START_LOADING";
export const TEAM_STOP_LOADING ="TEAM_STOP_LOADING";



/*Settings*/

export const SET_GET = "SET_GET"; 
export const SET_START_LOADING = "SET_START_LOADING";
export const SET_STOP_LOADING ="SET_STOP_LOADING";



