import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { FiMoreVertical, FiLogOut } from 'react-icons/fi';
import {MdSettings, MdPerson } from 'react-icons/md';
//import { Fade } from "@material-ui/core";
import Colors from '../../utils/colors';
import ThemeSizes from '../../utils/sizes';


const RightBox= ({ user})=>{
let [open, setOpen] = useState(false);
let [first, setFirst] = useState(true);
let popref = useRef(null);
let disp = first ? "none" : "block"; 
let navigate = useNavigate()
useEffect(() => {
   // listen for clicks and close dropdown on body
   document.addEventListener("mousedown", (e)=>{
      if(popref.current==null) return;
      if(popref.current.contains(e.target)) return;
      setOpen(false)
   });
   return () => {
     document.removeEventListener("mousedown", ()=>setOpen(false));
   };
 }, []);

 
let br = false;
function logout(){ //console.log(cookies)
   //removeCookie("Othello");
   window.setTimeout(()=>window.location.href="/", 1000);
}
return (
<>
   <div className="hide-on-mobile" style={{display:'flex', width:'225px', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
         <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
               <div >
                  { !user || user.img==null || !user.img || user.img==""? 
                  <div className={'userIconBox'} style={{backgroundColor:br ? "#9ac4e0":Colors.primary}}>
                     <span style={{fontWeight:"bold", fontSize:ThemeSizes.header.h6, color:br ? Colors.primary : Colors.lightWhite}}>
                        {!user || !user.fname ? "" : user.fname.split("")[0]}BD
                     </span>
                  </div>
                  :
                  <div>
                     <img className={'userIconBox'} src={user.img} />
                  </div>
                  }
               </div>
               <div>
                  <div className={'userName'} style={{color: br ? Colors.lightWhite : Colors.primary}}>
                     {user.fname+" "+user.surname}
                  </div>
                  <div className={'userEmail'} style={{color: br ? "#9ac4e0" : Colors.gray}}>
                     {user.email}
                  </div>
               </div>
            </div>
            <div  onClick={open ? null : ()=>{ setOpen(!open); setFirst(false) }} style={{justifyContent:'center', alignItems:'center', color:br ? "#9ac4e0": Colors.gray, fontWeight:600, fontSize:'1.2em', cursor:'pointer'}}>
               <FiMoreVertical />
            </div>
   </div>
   <div className="show-on-mobile" style={{paddingLeft:"8px", minWidth:"40px", }}>
       <div  onClick={open ? null : ()=>{ setOpen(!open); setFirst(false) }} style={{justifyContent:'center', alignItems:'center', display:'flex', width:'100%', height:'100%', color:br ? "#9ac4e0": Colors.gray, fontWeight:600, fontSize:'1.2em', cursor:'pointer'}}>
               <FiMoreVertical />
            </div>
   </div>
   <div style={{position:'relative'}}> 
      <div ref={popref} id={'popContainer'} className={open ? "animate__animated animate__slideInRight" : "animate__animated animate__slideOutRight"} style={{
            position:'absolute', top:'12px', right:'5px', display:disp, zIndex:100,
         }}>
         <div   style={{ width:'100%', padding:"20px 10px", borderRadius:'5px',
            backgroundColor:Colors.light,
         }}>
            <div className={'popItem'} style={{marginBottom:'12px'}}>
               <div style={{display:'flex', width:"100%", alignItems:'center'}}>
                  <div className={'userIconBox'} style={{backgroundColor:Colors.primary, display:"inline-block" }}>
                     <div style={{fontWeight:600, fontSize:ThemeSizes.header.h5, color:Colors.lightWhite}}>
                        {!user || !user.fname ? "" : user.fname.split("")[0]}
                     </div>
                  </div>
                  
                  <div style={{display:'inline-block', width:"calc(100% - 50px)"}}>
                     <div style={{display:'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <div>
                           <div style={{fontWeight:600, fontSize:'14px', color:Colors.black}}>
                              {!user || user==null ? "" : user.fname+" "+user.surname}
                           </div>
                           <div style={{fontSize:'12px', color:Colors.gray}}>
                              {!user || user==null ? "" : user.email}
                           </div>
                        </div>
                        <div style={{width:'10px', height:'10px', borderRadius:'50%', backgroundColor:'#0df70d'}}></div>
                     </div>
                  </div>
               </div>
            </div>
            <div className={'popItem'} onClick={()=>navigate("/settings")}>
               <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <div className={'userIconBox'} style={{backgroundColor:Colors.bluegray}}>
                     <MdPerson size={22} color={Colors.primary} />
                  </div>
                  <div style={{fontSize:'14px', fontWeight:600, color:Colors.gray  }}>
                     Profile
                  </div>
               </div>
            </div>
            <div className={'popItem'}  onClick={()=>navigate("/settings")}>
               <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <div className={'userIconBox'} style={{backgroundColor:Colors.bluegray}}>
                     <MdSettings size={22} color={Colors.primary} />
                  </div>
                  <div style={{fontSize:'14px', fontWeight:600, color:Colors.gray  }}>
                     Settings
                  </div>
               </div>
            </div>
            <div className={'popItem'}  onClick={logout}>
               <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <div className={'userIconBox'} style={{backgroundColor:Colors.bluegray}}>
                     <FiLogOut size={18} color={Colors.danger} style={{fontWeight:600}} />
                  </div>
                  <div style={{fontSize:'14px', fontWeight:600, color:Colors.danger  }}>
                     Logout
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</>
)
}
export default RightBox;