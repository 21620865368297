import React, {useState, useEffect} from "react";
import { MdVerified } from "react-icons/md";
import { useParams, Link } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';
import Colors from "../../utils/colors";
import useAlert from "../../utils/toast";
import useApi from "../../utils/api";
import useConfirm from "../../utils/confirm";
import TextGridRows from "./components/gridrow";
import CellLink from "./components/cell-link";
import DirectorsList from "./components/direct";
import PrimaryButton from "../../components/button/primary";
import SwitchBox from "../../components/input/switch";
import ConfirmDialogModal from "../../components/input/confirm";





function CompanyDetailsView(){
let [loading, setLoading] = useState(false);
let [busy, setBusy] = useState(false);
let [status, setStatus] = useState(null)
let [openConfirm, setOpenConfirm] = useState(false)
let [d, setD] = useState(null);
let [err, setErr] = useState();
let params = useParams();
let cid = params?.cid;

let api = useApi()
let alert = useAlert();
let confirm = useConfirm(); 

useEffect(()=>{ 
    if(!cid || cid==undefined || cid==null) return;
    getCompany();
}, [cid])

async function getCompany(){
    setLoading(true); setErr(null);
    let r = await api.post("companies/details", {company_id:cid});
    setLoading(false)
    if(r.status == 'error'){
        alert.toast(r.message, 'error');
        setErr(r.message); return;
    }
    if(r.status=="ok") {    setD(r.data); setStatus(r?.data?.status=="active")   }
}

async function toggleCompany() {
    let m = status == true ?
            "Are you sure you want to suspend "+d?.name + " on MonyTrack Business? Once suspended, "+d?.name +" will no longer be able to access MonyTrack Business. All login attempt by the company will fail" :
            "Are you sure you want to reactivate "+d?.name +" on Monytrack Business? This will restore access to MonyTrack Business."

    let c = await window.confirm(m);
    if(c==true){
        setBusy(true)
        let r = await api.post("companies/toggle", {company_id:cid, action:status==true ? "deactivate":"activate"});
        setBusy(false)
        if(r.status=="error"){
            alert.toast(r.message, "error"); return;
        }
        if(r.status=="ok"){
            alert.toast(r.message, "success"); setStatus(!status);
            d.status = "suspended"; setD(JSON.parse(JSON.stringify(d)));
        }
    }
}

async function verifyCompany() {
    setOpenConfirm(!openConfirm)
}
async function processConfirmation(c) {
    if(c=="reject") return;
    if(c=="accept"){
        setBusy(true)
        let r = await api.post("companies/approve", {company_id:cid});
        setBusy(false);
        if(r.status=='error') { alert.toast(r.message, "error"); return}
        if(r.status=="ok"){
            alert.toast(r.message, "success");
            getCompany();
        }
    }
}


return(
    <div style={{width:"100%", padding:"1em", backgroundColor:Colors.bg1, borderRadius:"5px"}}>
    { 
        loading ?
        <div>
            <Skeleton width="100%" height="15rem" borderRadius="10px"></Skeleton>
        </div>
        : err != null ?
        <center>
            <div style={{textAlign:"center", padding:"4em 0em"}}>
                <div className="h5 bold"> Something went wrong 😔</div>
                <div className="h6" style={{color:Colors.gray, margin:"10px 0px 5px 0px"}}>{err}</div>
                <div>
                    <Link to={"/companies"} style={{color:Colors.primary}} className="h7 links"> Go back to companies {">>"}</Link>
                </div>
            </div>
        </center>
        :
        <div>
            <div className="bar-title">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"space-between" }}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center' }}>
                        <div className="bold h4">{d?.name}</div>
                        <MdVerified size={20} color={d?.verification_status=="verified" ? Colors.green : Colors.gray} style={{marginLeft:"10px"}} />
                    </div>
                    <div>
                        <SwitchBox status={status} 
                            callback={toggleCompany} tip={"Manage company status"}
                         />
                    </div>
                </div>
            </div>
            <div className="b-bottom h5 regular" style={{width:"100%", marginTop:"10px", color:Colors.black}}>
                Business Basic Information:
            </div>
            <TextGridRows grid={[
                {label:"Business Name", text:d?.name}, {label:"Business Alias", text:d?.alias},
                {label:"Country", text:d?.country}, {label:"Currency", text:d?.currency},
            ]} />
            <TextGridRows grid={[
                {label:"Business Description", text:d?.business_description, w:'6'}, {label:"Category", text:d?.category?.label, w:"3"},
                {label:"Subcategory", text:d?.subcategory?.label, w:'3'},
            ]} />
            <TextGridRows grid={[
                {label:"Address", text:d?.address, w:"6"}, {label:"Region/State", text:d?.state, w:"3"},
                {label:"City", text:d?.city, w:"3"},
            ]} />
            <br />
            <div className="b-bottom h5 regular" style={{width:"100%", marginTop:"10px", color:Colors.black}}>
                Business Contact:
            </div>
            <TextGridRows grid={[
                {label:"Business Email", text:d?.email}, {label:"Business Phone", text:d?.phone},
                {label:"Created By", text:d?.created_by}, 
                {label:"Creator Contact", text:d?.creator_email + " | " + d?.creator_phone},
            ]} />

            <br />
            <div className="b-bottom h5 regular" style={{width:"100%", marginTop:"10px", color:Colors.black}}>
                Business Legal:
            </div>
            <TextGridRows grid={[
                {label:"Legal Status", text:d?.legal_status?.label}, {label:"Registration No", text:d?.regno},
                {label:"Registration Date", text:d?.reg_date}, {label:"Business TIN", text:d?.tinno},
            ]} />

            <br />
            <div className="b-bottom h5 regular" style={{width:"100%", marginTop:"10px", color:Colors.black}}>
                Business Documents:
            </div>
            <TextGridRows grid={[
                {label:"Registration Document", text:<CellLink text={"Registration Documents"} link={d?.docs} />}, 
                {label:"Business License", text: <CellLink text={"Licenses"} link={d?.license} />},
                {label:"Utility Bill", text:<CellLink text={"Proof of Address"} link={d?.utility} />}, 
                {label:"AML Policy", text:<CellLink text={"Policy Document"} link={d?.aml_p} /> },
            ]} />
            <br />
            <div className="b-bottom h5 regular" style={{width:"100%", marginTop:"10px", color:Colors.black}}>
                Business Directors & Shareholders:
            </div>
            <br />
            <DirectorsList people={d?.people} cid={cid}/>
            <br />
            <div className="b-bottom h6 medium" style={{width:"100%", marginTop:"15px", color:Colors.black}}>
                Action:
            </div>
            <div style={{marginTop:"15px"}}>
                <div className="txt-col-label-2 h65">Mark company compliance verification as complete if the provided information meets compliance requirements. Note that this is a critical action. It will make the company active on the system and give them access to all services on MonyTrack Business. Ensure that due diligence has been done before approving a company.</div>
                
                <div style={{marginTop:"15px"}}>
                    <PrimaryButton
                        label={"Approve Company Verification"} size={"small"} busy={busy} callback={verifyCompany}
                    />
                </div>
            </div>
            <br /><br />
            <ConfirmDialogModal 
                open={openConfirm} close={()=>setOpenConfirm(false)} callback={processConfirmation}
                title={"Confirm company approval"}
                message={"Are you sure you want to proceed to approve company on MonyTrack Business?"}
                aLabel="Yes, approve" rlabel="No, cancel"
             />
        </div>
    }
    </div>
)
}
export default CompanyDetailsView;



