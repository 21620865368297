import React from 'react';
import PageBuilder from '../../utils/pagebuilder';

import SwiftPaymentsView from '../../views/dash/swift-v';

function SwiftPaymentsPage(){ 

return <PageBuilder
         PageComponent = {SwiftPaymentsView}
         access = ""
         title = ""
         header = "Swift Payments"
         pageProps={{ }}
       />

}
export default SwiftPaymentsPage;