import { DASH_START_LOADING, DASH_STOP_LOADING, DASH_COMPLY, DASH_INACTIVES, DASH_SWIFT } from "../types";


export function dashStartLoading(){ 
    return {
       type: DASH_START_LOADING,
    }
 }
export function dashStopLoading(){ 
    return {
       type: DASH_STOP_LOADING,
    }
}


export function dashGetInactives(api, toast){ 
    return function (dispatch, state){
       dispatch(dashStartLoading());
       api.get("dashboard/inactives", {}).then((result)=>{
          if(result==null || !result.status){
             toast.toast( result==null ? "Something went wrong": result.message, 'error');
             dispatch(dashStopLoading()); return;
          }
          if(result.status=="error"){
             toast.toast( result.message);
             dispatch(dashStopLoading()); return;
          }
          else if( result.status=="ok"){
             dispatch( { type:DASH_INACTIVES,  payload:{list:result.data, timestamp:(new Date).getTime() } });
             dispatch(dashStopLoading()); 
          }
       }).catch((e)=>{
          toast.toast("Something went wrong: "+ e.message);
          dispatch(dashStopLoading())
       });
    }
}


export function dashGetCompanies(api, toast){ 
    return function (dispatch, state){
       dispatch(dashStartLoading());
       api.get("companies/list", {}).then((result)=>{
          if(result==null || !result.status){
             toast.toast( result==null ? "Something went wrong": result.message, 'error');
             dispatch(dashStopLoading()); return;
          }
          if(result.status=="error"){
             toast.toast( result.message);
             dispatch(dashStopLoading()); return;
          }
          else if( result.status=="ok"){
             dispatch( { type:DASH_COMPLY,  payload:{list:result.data, timestamp:(new Date).getTime() } });
             dispatch(dashStopLoading()); return; 
          }
       }).catch((e)=>{
          toast.toast("Something went wrong: "+ e.message);
          dispatch(dashStopLoading())
       });
    }
}




export function dashGetSwiftPayments(api, toast){ 
    return function (dispatch, state){
       dispatch(dashStartLoading());
       api.get("companies/list", {}).then((result)=>{
          if(result==null || !result.status){
             toast.toast( result==null ? "Something went wrong": result.message, 'error');
             dispatch(dashStopLoading()); return;
          }
          if(result.status=="error"){
             toast.toast( result.message);
             dispatch(dashStopLoading()); return;
          }
          else if( result.status=="ok"){
             dispatch( { type:DASH_SWIFT,  payload:{list:result.data, timestamp:(new Date).getTime() } });
             dispatch(dashStopLoading()); return; 
          }
       }).catch((e)=>{
          toast.toast("Something went wrong: "+ e.message);
          dispatch(dashStopLoading())
       });
    }
}