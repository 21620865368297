import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Colors from "../../utils/colors";
import useAlert from "../../utils/toast";
import useApi from "../../utils/api";
import { dashGetCompanies, dashStopLoading } from "../../redux/actions/dashActions";



function CompaniesView(){
let [list, setList] = useState([])

let api = useApi();
let alert = useAlert();
let dispatch = useDispatch();
let loading = useSelector((s)=>s.Dash?.Loading); //console.log("L", loading)
let comps = useSelector((s)=>s.Dash?.Companies); //console.log("Comps", comps)
const items = Array.from({ length: 5 }, (v, i) => i);

useEffect(()=>{dispatch(dashStopLoading())
    if(!comps || comps==null ){
        dispatch(dashGetCompanies(api, alert))
    } else {
        let last3 = (new Date).getTime() - 60*60*3*1000; 
        if(comps?.timestamp < last3){ 
            dispatch(dashGetCompanies(api, alert));
        } else {    setList(comps?.list)}
    }
}, [comps?.list])

function ViewComp(c){
    return <div className="table-view-link"><Link to={"/companies/"+c.cid}>View</Link></div>
}

return(
<>
<div style={{width:"100%", padding:"1em", backgroundColor:Colors.bg1, borderRadius:"5px"}}>
        <div className="bold">Companies - Compliance Check</div>
        <br/>

        {   loading ?
            <DataTable value={items} className="p-datatable-striped" style={{marginTop:"10px"}}>
                <Column field="code" header="Company" style={{ width: '25%' }} body={<Skeleton />}></Column>
                <Column field="name" header="Created By" style={{ width: '25%' }} body={<Skeleton />}></Column>
                <Column field="category" header="Country" style={{ width: '25%' }} body={<Skeleton />}></Column>
                <Column field="quantity" header="Email"  body={<Skeleton />}></Column>
                <Column field="quantity" header="Verification"  body={<Skeleton />}></Column>
                <Column field="quantity" header="Action"  body={<Skeleton />}></Column>
            </DataTable>
            :
            list && list?.list?.length > 0 ?

            <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:'10px'}}>
                    <DataTable resizableColumns 
                        value={list?.list} size="small" stripedRows ={false} rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="sn" header="#" className="h65 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="name" header="Company" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="initiated_by" header="Created By" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="country" header="Country" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="email" header="B Email" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="phone" header="B Phone" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="date" header="Date" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="verification_status" header="Verification" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="status" header="Status" className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="qty" header="Action" body={ViewComp} className="h65 regular" headerClassName={'h6 bold table-head'}></Column>
                    </DataTable>
                    <Paginator first={1} rows={list?.list?.rows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={()=>0} />
            </div>
            :
            <div className="h6" style={{marginTop:"10px", color:Colors.gray}}>
                There are no results.
            </div>
        }
    </div>
    <style>{`
    .table-head {background-color: ${Colors.bg2}; color:${Colors.black}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</>
)
}
export default CompaniesView;