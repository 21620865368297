import React, {useState, useEffect} from "react";
import { Dialog } from 'primereact/dialog';
import TextGridRows from "./gridrow";
import Colors from "../../../utils/colors";
import PrimaryButton from "../../../components/button/primary";
import useApi from "../../../utils/api";
import useAlert from "../../../utils/toast";

function DirectorModal({open, close, d, cid}){
let [busy, setBusy] = useState(false)
let bd = d?.id_data ? JSON.parse(d?.id_data) : {}
let api = useApi()
let alert = useAlert();  //console.log(d)

let s3_base = "https://monytrack.s3.eu-west-2.amazonaws.com/mtb/c/identities/docs/"
let s3_base2 = "https://monytrack.s3.eu-west-2.amazonaws.com/mtb/c/identities/selfie/"

async function VerifyDirector(){
    let c = await window.confirm("Are you sure you want to mark this identity as verified?");
    if(c==true){
        setBusy(true)
        let r = await api.post("companies/verify-director", {company_id:cid, guid:d?.guid});
        setBusy(false)
        if(r.status=="error"){
            alert.toast(r.message, "error"); return;
        }
        if(r.status=="ok"){
            alert.toast(r.message, "success"); close();
        }
    }
}

return(
<>
    <div>
        <Dialog 
            header={ "Details"} 
            visible={open} style={{ width: '80vw' }} maximizable onHide={close}>
            <div className="b-bottom h6 medium" style={{width:"100%", color:Colors.black}}>
                BVN Data:
            </div>
            <TextGridRows grid={[
                {label:"Firstname", text:d?.firstname},    {label:"Middlename", text:bd?.middlename},
                {label:"Lastname", text:d?.lastname},  {label:"Phone", text:bd?.phone_number1 },
            ]} />
            <TextGridRows grid={[
                {label:"Email", text:d?.email },     {label:"Date of Birth", text:bd?.date_of_birth, w:"3"}, 
                {label:"Gender", text:bd?.gender, w:'3'}, {label:"Verification Status", text:d?.verification_status, w:"3"},    
            ]} />
            <br />
            <div className="border row no-margin" style={{borderRadius:"5px", marginTop:"15px"}}>
                <div className="col-md-6 no-padding">
                    <div className="h6 medium b-right b-bottom" style={{padding:"5px"}}>BVN Photo</div>
                    <div className="b-right" style={{padding:"3px"}}>
                        <img src={s3_base+bd?.image} style={{width:"100%"}} />
                    </div>
                </div>
                <div className="col-md-6 no-padding">
                    <div className="h6 medium b-bottom" style={{padding:"5px"}}>Selfie</div>
                    <div className="" style={{padding:"3px"}}>
                        <img src={s3_base2+ d?.selfie_link} style={{width:"100%"}} />
                    </div>
                </div>

                <div className="col-md-12 no-padding">
                    <div className="h6 medium b-bottom b-top" style={{padding:"5px"}}>ID Document</div>
                    <div className="" style={{padding:"3px"}}>
                        <img src={s3_base+ d?.document_link} style={{maxWidth:"100%"}} />
                    </div>
                </div>
            </div>
            <br />
            <div className="b-bottom h6 medium" style={{width:"100%", color:Colors.black}}>
                Action:
            </div>
            <div style={{marginTop:"15px"}}>
                <div className="txt-col-label-2 h7">Mark verification as complete if the provided information meets compliance requirements</div>
                
                <PrimaryButton
                    label={"Comfirm Verification"} size={"small"} busy={busy} callback={VerifyDirector}
                 />
            </div>
        </Dialog>
    </div>
</>
)
}
export default DirectorModal;