import React from 'react';
import PageBuilder from '../../utils/pagebuilder';

import CompanyDetailsView from '../../views/dash/comps-d-v';

function CompanyDetailsPage(){ 

return <PageBuilder
         PageComponent = {CompanyDetailsView}
         access = ""
         title = ""
         header = "Businesses Details"
         pageProps={{ }}
       />

}
export default CompanyDetailsPage;