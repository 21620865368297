import React, {FC, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
//import Permissions from './perms';
import useGuard from './guard/guard';

//import AccessDenied from '../views/accessDenied';




const AccessManager = ({access, children})=>{ 
let [perm, setPerm] = useState(null);
const auth = useSelector((state)=>state?.Auth?.Auth); 
let guard = useGuard();
let navigate = useNavigate()

useEffect(()=>{ 
   if(guard !==true){
      navigate("/"); return;
   }
   if(auth==null ) { return; }
   //let hasPerm = auth?.user?.role =="master" ? true : JSON.parse(auth?.user.permissions).includes(access.replace(/_/g, " ") );
   //setPerm(hasPerm)
},[]); 

if(auth==null)  return null; 
//if(perm==null) return null;
//if( perm==false ) return <AccessDenied message={Permissions[access].warn} />

return(
   <div>
      {children}
   </div>
)
}
export default AccessManager;