import React from 'react';
import PageBuilder from '../../utils/pagebuilder';

import CompaniesView from '../../views/dash/comps-v';

function CompaniesPage(){ 

return <PageBuilder
         PageComponent = {CompaniesView}
         access = ""
         title = ""
         header = "Businesses"
         pageProps={{ }}
       />

}
export default CompaniesPage;