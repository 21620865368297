import React, {useRef} from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Colors from "./colors";
import { render } from "@testing-library/react";



function useConfirm(){

}
export default useConfirm;