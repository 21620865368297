import DashboardPage from "../pages/dash/dash";
import CompaniesPage from "../pages/dash/comply";
import CompanyDetailsPage from "../pages/dash/comp-d-p";
import SwiftPaymentsPage from "../pages/dash/pay-s-p";

const AuthenticatedRoutes = [
    {
        path: "/dashboard",
        element: <DashboardPage />,
    },
    {
        path: "/companies",
        element: <CompaniesPage />,
    },
    {
        path: "/companies/:cid",
        element: <CompanyDetailsPage />,
    },
    {
        path: "/payments-swift",
        element: <SwiftPaymentsPage />,
    },
   
];

export default AuthenticatedRoutes;