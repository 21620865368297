import React from 'react';
//import {useSpring, animated} from 'react-spring';
import Colors from '../../../utils/colors';
import { GiTakeMyMoney } from 'react-icons/gi';

import { FaCoins, FaRenren, FaStumbleupon, FaChartPie, FaPeopleArrows, FaLink} from 'react-icons/fa';



function AuthSidebar(){
return(
   <div style={{width:"100%", height:"100%", minHeight:"100vh", backgroundColor:Colors.bg2, padding:"2.5em 1em", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div className='col-md-11' >
         <div className='bold h1' style={{marginBottom:"5px", textAlign:"left"}}>MonyTrack Admin.</div>
         <div className='h5' style={{marginBottom:"25px", textAlign:"left"}}>Welcome to MonyTrack Admin portal.</div>
      </div>
   </div>
)
}
export default AuthSidebar;