import React from 'react';
import PageBuilder from '../../utils/pagebuilder';

import DashboardView from '../../views/dash/dash-v';

function DashboardIndex(){ 

return <PageBuilder
         PageComponent = {DashboardView}
         access = ""
         title = "Dashboard"
         header = "Dashboard"
         pageProps={{ }}
       />

}
export default DashboardIndex;