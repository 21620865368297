import { combineReducers } from "redux";
import AuthReducer from "./auth";
import AuthModalReducer from "./authModal";
import DashReducer from "./dashReducer";


const rootReducer = combineReducers(
   {
      Auth:AuthReducer,
      AM:AuthModalReducer,
      Dash:DashReducer,
   }
);

export default rootReducer;