import { DASH_START_LOADING, DASH_STOP_LOADING, DASH_COMPLY, DASH_INACTIVES, DASH_SWIFT } from "../types";

const initialState ={ 
    Loading:false,
    Inactives:null,
    Companies:null,
    Payments:null
}


 
const DashReducer =(state=initialState, action)=>{ 
    switch(action.type){
       case DASH_START_LOADING: 
          return {
             ...state,
             Loading:true,
          }
       case DASH_STOP_LOADING: 
          return {
             ...state,
             Loading:false,
          }
       case DASH_INACTIVES:
          return {
             ...state,
             Inactives:action.payload
          };
       case DASH_COMPLY:
          return {
             ...state,
             Companies:action.payload
          };
       case DASH_SWIFT:
          return {
             ...state,
             Payments:action.payload
          };
       default:
          return state
    }
  }
  
  
  export default DashReducer;